const awsmobile = {
    "aws_project_region": "us-east-1", // e.g., "us-east-1"
    "aws_cognito_region": "us-east-1", // Should match aws_project_region
    "aws_user_pools_id": "us-east-1_krfHjjx8k",
    "aws_user_pools_web_client_id": "hm2am1df2p6m7rrbfq56ha9eg",
    "aws_user_pools_web_client_secret": "1p37t3aqhv9n6lklp0r2j7d90d3pob585343fu1o16cilq5gtepl",
    "oauth": {
        "domain": "tof.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://dashboard.townoffun.com",
        "redirectSignOut": "https://dashboard.townoffun.com",
        "responseType": "grant"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    // ... other configurations (if any) should remain unchanged
};

export default awsmobile;