import React from 'react';

const LandingPage = () => {
  return (
    <div>
      <h1>Welcome to the Landing Page!</h1>
      <p>You are successfully logged in.</p>
    </div>
  );
};

export default LandingPage;
