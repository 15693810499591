import React, { useState, useEffect } from 'react';
import { getAllPunchCards, getPunchCardsByEmail, getPunchCardById, updatePunchCard } from '../services/api';
import { useLocation } from 'react-router-dom';
import './PunchCardsDashboard.css'; // Custom CSS file for styling

const PunchCardsDashboard = () => {
    const [punchCards, setPunchCards] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPunchCards, setFilteredPunchCards] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [scannedCard, setScannedCard] = useState(null);
    const location = useLocation();

    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const cardId = queryParams.get('id');

    // Fetch all punch cards on component mount
    useEffect(() => {
        if (cardId) {
            fetchPunchCardById(cardId); // Fetch punch card if 'id' is present in the query
        } else {
            fetchAllPunchCards(); // Fetch all punch cards if no 'id' is present
        }
    }, [cardId]);

    const fetchAllPunchCards = async () => {
        try {
            const response = await getAllPunchCards(); // API to fetch all punch cards
            setPunchCards(response.data);
            setFilteredPunchCards(response.data); // Default to showing all cards
        } catch (error) {
            console.error('Error fetching punch cards:', error);
            setErrorMessage('Failed to fetch punch cards.');
        }
    };

    const fetchPunchCardById = async (id) => {
        try {
            const response = await getPunchCardById(id); // Fetch punch card by ID
            setScannedCard(response.data);
        } catch (error) {
            setErrorMessage('No punch card found for the provided ID.');
            console.error('Error fetching punch card by ID:', error);
        }
    };

    // Handle search by email
    const handleSearch = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (!searchQuery) {
            setErrorMessage('Please enter an email to search.');
            return;
        }

        try {
            const response = await getPunchCardsByEmail(searchQuery); // API to fetch cards by email
            setFilteredPunchCards(response.data);
        } catch (error) {
            setErrorMessage('No punch cards found for the provided email.');
            console.error(error);
        }
    };

    // Handle "punching" the card
    const handlePunchCard = async (card) => {
        if (card.remaining_punches <= 0) {
            alert('This card has no remaining punches.');
            return;
        }

        const updatedCard = {
            id: card.id,
            remaining_punches: card.remaining_punches - 1,
        };

        try {
            await updatePunchCard(updatedCard); // API to update the punch card
            if (cardId) {
                fetchPunchCardById(cardId); // Refresh the single card if an ID is in the query
            } else {
                fetchAllPunchCards(); // Refresh the list of punch cards
            }
        } catch (error) {
            console.error('Error punching the card:', error);
            setErrorMessage('Failed to update punch card.');
        }
    };

    return (
        <div className="container">
            <h2>Punch Cards Dashboard</h2>

            {cardId && scannedCard ? (
                <div>
                    <h3>Scanned Punch Card Details</h3>
                    <p>Email: {scannedCard.email}</p>
                    <p>Total Punches: {scannedCard.total_punches}</p>
                    <p>Remaining Punches: {scannedCard.remaining_punches}</p>
                    <button
                        disabled={scannedCard.remaining_punches <= 0}
                        onClick={() => handlePunchCard(scannedCard)}
                    >
                        Punch
                    </button>
                </div>
            ) : (
                <>
                    <h3>All Punch Cards</h3>
                    {punchCards.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Total Punches</th>
                                    <th>Remaining Punches</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredPunchCards.map((card) => (
                                    <tr key={card.id}>
                                        <td>{card.email}</td>
                                        <td>{card.total_punches}</td>
                                        <td>{card.remaining_punches}</td>
                                        <td>{new Date(card.created_at).toLocaleDateString()}</td>
                                        <td>{new Date(card.updated_at).toLocaleDateString()}</td>
                                        <td>
                                            <button
                                                disabled={card.remaining_punches <= 0}
                                                onClick={() => handlePunchCard(card)}
                                            >
                                                Punch
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No punch cards found.</p>
                    )}

                    <h3>Search Punch Cards by Email</h3>
                    <form onSubmit={handleSearch}>
                        <input
                            type="email"
                            placeholder="Enter email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="submit">Search</button>
                    </form>
                </>
            )}

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default PunchCardsDashboard;
