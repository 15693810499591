import axios from 'axios';

const API_BASE_URL = 'https://api.townoffun.com';

export const setAuthToken = (token) => {
    console.log('Setting auth token:', token);
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        console.log('Authorization header set');
    } else {
        delete axios.defaults.headers.common['Authorization'];
        console.log('Authorization header removed');
    }
};

export const getMembers = async () => {
    return axios.get(`${API_BASE_URL}/members`);
};

export const getWaivers = async () => {
    return axios.get(`${API_BASE_URL}/waivers`);
};

export const getEventsToday = () => {
    return axios.get(`${API_BASE_URL}/reservations/today`);
};

// New Function: Get events by date range
export const getEventsByDateRange = async (fromDate, toDate) => {
    const params = new URLSearchParams({
        from: fromDate,
        to: toDate,
    });
    return await axios.get(`${API_BASE_URL}/reservations?${params.toString()}`);
};

export const updateReservation = (reservation) => {
    return axios.put(`${API_BASE_URL}/reservations`, reservation);
};

export const getActiveMembers = () => {
    return axios.get(`${API_BASE_URL}/subscriptions/active`);
};

export const getCancelledMembers = () => {
    return axios.get(`${API_BASE_URL}/subscriptions/cancelled`);
};

export const getMemberByPhoneOrEmail = (query) => {
    return axios.get(`${API_BASE_URL}/subscriptions/member`, { params: query });
};

export const addWalkIn = async (walkInData) => {
    return await axios.post(`${API_BASE_URL}/walkins`, walkInData);
};

// New Function: Get all punch cards
export const getAllPunchCards = async () => {
    return await axios.get(`${API_BASE_URL}/punch-card`);
};

// New Function: Get punch cards by email
export const getPunchCardsByEmail = async (email) => {
    return await axios.get(`${API_BASE_URL}/punch-card`, { params: { email } });
};

export const getPunchCardById = async (id) => {
    return await axios.get(`${API_BASE_URL}/punch-card`, { params: { id } });
};

// New Function: Update a punch card (reduce remaining punches)
export const updatePunchCard = async (card) => {
    return await axios.put(`${API_BASE_URL}/punch-card`, {
        id: card.id,
        remaining_punches: card.remaining_punches,
    });
};

export const getAllInventoryItems = async () => {
    return await axios.get(`${API_BASE_URL}/inventory`);
};

export const createInventoryItem = async (item) => {
    return await axios.post(`${API_BASE_URL}/inventory`, item);
};

export const deleteInventoryItem = async (id) => {
    return await axios.delete(`${API_BASE_URL}/inventory/${id}`);
};

export const updateInventoryItem = async (id, updateData) => {
    return await axios.put(`${API_BASE_URL}/inventory/${id}`, updateData);
};

