import React, { useState, useEffect } from 'react';
import { getAllInventoryItems, createInventoryItem, deleteInventoryItem, updateInventoryItem } from '../services/api';
import './InventoryDashboard.css';

const InventoryDashboard = () => {
    const [inventoryItems, setInventoryItems] = useState([]);
    const [newItem, setNewItem] = useState({ name: '', amount_left: 0, low_inventory_amount: 0 });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchAllInventoryItems();
    }, []);

    const fetchAllInventoryItems = async () => {
        try {
            const response = await getAllInventoryItems();
            setInventoryItems(response.data);
        } catch (error) {
            console.error('Error fetching inventory items:', error);
            setErrorMessage('Failed to fetch inventory items.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateItem = async () => {
        setErrorMessage('');
        setSuccessMessage('');

        if (!newItem.name || newItem.amount_left < 0 || newItem.low_inventory_amount < 0) {
            setErrorMessage('Please provide valid item details.');
            return;
        }

        try {
            await createInventoryItem(newItem);
            setSuccessMessage('Item added successfully!');
            setNewItem({ name: '', amount_left: 0, low_inventory_amount: 0 });
            fetchAllInventoryItems();
        } catch (error) {
            console.error('Error creating inventory item:', error);
            setErrorMessage('Failed to create item.');
        }
    };

    const handleDeleteItem = async (id) => {
        setErrorMessage('');
        setSuccessMessage('');

        try {
            await deleteInventoryItem(id);
            setSuccessMessage('Item deleted successfully!');
            fetchAllInventoryItems();
        } catch (error) {
            console.error('Error deleting inventory item:', error);
            setErrorMessage('Failed to delete item.');
        }
    };

    const handleUpdateItem = async (updatedItem) => {
        setErrorMessage('');
        setSuccessMessage('');

        if (updatedItem.amount_left < 0 || updatedItem.low_inventory_amount < 0) {
            setErrorMessage('Amount left and low inventory warning must be non-negative.');
            return;
        }

        try {
            await updateInventoryItem(updatedItem.id, {
                name: updatedItem.name,
                amount_left: updatedItem.amount_left,
                low_inventory_amount: updatedItem.low_inventory_amount,
            });
            setSuccessMessage('Item updated successfully!');
            fetchAllInventoryItems();
        } catch (error) {
            console.error('Error updating inventory item:', error);
            setErrorMessage('Failed to update item.');
        }
    };

    return (
        <div className="container">
            <h2>Inventory Dashboard</h2>

            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Amount Left</th>
                                <th>Low Inventory Threshold</th>
                                <th>Date Added</th>
                                <th>Updated At</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inventoryItems.map((item) => (
                                <tr
                                    key={item.id}
                                    className={item.amount_left < item.low_inventory_amount ? 'low-inventory' : ''}
                                >
                                    <td>{item.name}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={item.amount_left}
                                            onChange={(e) =>
                                                handleUpdateItem({
                                                    ...item,
                                                    amount_left: parseInt(e.target.value, 10),
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={item.low_inventory_amount}
                                            onChange={(e) =>
                                                handleUpdateItem({
                                                    ...item,
                                                    low_inventory_amount: parseInt(e.target.value, 10),
                                                })
                                            }
                                        />
                                    </td>
                                    <td>{new Date(item.date_added).toLocaleDateString()}</td>
                                    <td>{new Date(item.updated_at).toLocaleDateString()}</td>
                                    <td>
                                        <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h3>Add New Inventory Item</h3>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleCreateItem();
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Item Name"
                            value={newItem.name}
                            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                        />
                        <input
                            type="number"
                            placeholder="Amount Left"
                            value={newItem.amount_left}
                            onChange={(e) => setNewItem({ ...newItem, amount_left: parseInt(e.target.value, 10) })}
                        />
                        <input
                            type="number"
                            placeholder="Low Inventory Threshold"
                            value={newItem.low_inventory_amount}
                            onChange={(e) =>
                                setNewItem({ ...newItem, low_inventory_amount: parseInt(e.target.value, 10) })
                            }
                        />
                        <button type="submit">Add Item</button>
                    </form>
                </>
            )}

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </div>
    );
};

export default InventoryDashboard;
