import React, { useEffect, useState } from 'react';
import './WaiversDashboard.css'; // Import the CSS file

const WaiversDashboard = ({ tokens }) => {
    const [allWaivers, setAllWaivers] = useState([]); // Store all fetched waivers
    const [displayedWaivers, setDisplayedWaivers] = useState([]); // Waivers to show on the current page
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterField, setFilterField] = useState('all');
    
    // Add pagination state
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100); // Default to 100 results per page
    const [totalWaivers, setTotalWaivers] = useState(0);
    const [fetchedPages, setFetchedPages] = useState(0); // Track how many pages (of 1000 waivers) we've fetched from the backend

    // Fetch 1000 waivers from the backend
    const fetchWaiversFromBackend = async () => {
        if (!tokens || !tokens.access_token) {
            console.error('Missing access token');
            setError('Missing access token');
            setLoading(false);
            return;
        }

        setLoading(true); // Set loading to true whenever a new request is made
        setError(null); // Reset the error state

        try {
            console.log(`Fetching waivers page ${fetchedPages + 1} from backend (2000 waivers per fetch)`);
            const response = await fetch(`https://api.townoffun.com/webhook/waivers?page=${fetchedPages + 1}&limit=2000`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokens.access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch waivers: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            console.log('Fetched waivers from backend:', data);

            // Append new waivers to the existing waivers
            setAllWaivers(prevWaivers => [...prevWaivers, ...data.waivers]);
            setTotalWaivers(prevTotal => prevTotal + data.waivers.length); // Update total count of waivers fetched

            // Increase the fetchedPages count
            setFetchedPages(prevFetchedPages => prevFetchedPages + 1);
        } catch (error) {
            console.error('Error in fetchWaivers:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch the first set of 1000 waivers when the component mounts
    useEffect(() => {
        fetchWaiversFromBackend();
    }, [tokens]);

    // Paginate the waivers on the frontend (based on the internal page and limit)
    useEffect(() => {
        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;

        // Filter out waivers where both 'Birthday' and 'Child' fields are missing
        const filteredWaivers = allWaivers.filter(waiver => waiver.birthday || waiver.child);
        
        const waiversToDisplay = filteredWaivers.slice(startIndex, endIndex);
        setDisplayedWaivers(waiversToDisplay);
    }, [page, limit, allWaivers]);

    // Filter waivers based on search and filter field
    useEffect(() => {
        const filtered = allWaivers.filter(waiver => {
            const searchRegex = new RegExp(searchTerm, 'i');
            if (filterField === 'all') {
                return Object.values(waiver).some(value => 
                    typeof value === 'string' && searchRegex.test(value)
                );
            } else {
                return searchRegex.test(waiver[filterField]);
            }
        });

        // Apply the birthday and child field filtering here as well
        const filteredWaivers = filtered.filter(waiver => waiver.birthday || waiver.child);
        
        setDisplayedWaivers(filteredWaivers.slice((page - 1) * limit, page * limit)); // Paginate the filtered results
    }, [allWaivers, searchTerm, filterField, page, limit]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(1); // Reset to the first page when search term changes
    };

    const handleFilterChange = (e) => {
        setFilterField(e.target.value);
        setPage(1); // Reset to the first page when filter changes
    };

    // Check if the user is on the last page of the currently fetched waivers
    const isOnLastPageOfCurrentWaivers = page * limit >= allWaivers.length;

    // Handle pagination
    const handleNextPage = () => {
        if (isOnLastPageOfCurrentWaivers && allWaivers.length < totalWaivers) {
            // Fetch the next 1000 waivers from the backend if available
            fetchWaiversFromBackend();
        }
        if (!isOnLastPageOfCurrentWaivers) {
            console.log(`Next Page: ${page + 1}`);
            setPage(prevPage => prevPage + 1);  // Increment the page and trigger a re-fetch
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            console.log(`Previous Page: ${page - 1}`);
            setPage(prevPage => prevPage - 1);  // Decrement the page and trigger a re-fetch
        }
    };

    const handleLimitChange = (e) => {
        setLimit(Number(e.target.value));
        setPage(1);  // Reset to first page when limit changes
    };

    return (
        <div className="container">
            <h2>Liability Waivers Dashboard</h2>

            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}

            {!loading && !error && (
                <>
                    <div>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <select value={filterField} onChange={handleFilterChange}>
                            <option value="all">All Fields</option>
                            <option value="birthday">Birthday</option>
                            <option value="child">Child</option>
                            <option value="email">Email</option>
                            <option value="parent">Parent</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="limit">Results per page: </label>
                        <select id="limit" value={limit} onChange={handleLimitChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>

                    <p>Total Waivers: {displayedWaivers.length} (Page {page})</p>

                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Birthday</th>
                                <th>Child</th>
                                <th>Email</th>
                                <th>Parent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedWaivers.map((waiver) => (
                                <tr key={waiver._id}>
                                    <td>{waiver._id}</td>
                                    <td>{waiver.birthday || 'N/A'}</td>
                                    <td>{waiver.child || 'N/A'}</td>
                                    <td>{waiver.email}</td>
                                    <td>{waiver.parent}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="pagination">
                        <button disabled={page === 1} onClick={handlePreviousPage}>Previous</button>
                        <button disabled={isOnLastPageOfCurrentWaivers && allWaivers.length === totalWaivers} onClick={handleNextPage}>Next</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default WaiversDashboard;
