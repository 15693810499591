import React, { useState, useEffect } from 'react';
import { getActiveMembers, getCancelledMembers, getMemberByPhoneOrEmail } from '../services/api';
import { useLocation } from 'react-router-dom';
import './MembersDashboard.css'; // Import the CSS file

const MembersDashboard = () => {
    const [activeMembers, setActiveMembers] = useState([]);
    const [cancelledMembers, setCancelledMembers] = useState([]);
    const [searchQuery, setSearchQuery] = useState({ phone: '', email: '' });
    const [specificMember, setSpecificMember] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation(); // To access query parameters

    // Helper function to get query parameters from the URL
    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    // Fetch query parameters from the URL
    const queryParams = getQueryParams();
    const email = queryParams.get('email');
    const phone = queryParams.get('phone');

    // Automatically perform a search if email or phone is present in the URL
    useEffect(() => {
        if (email || phone) {
            getMemberByPhoneOrEmail({ email, phone })
                .then(response => {
                    setSpecificMember(response.data.member);
                })
                .catch(error => {
                    setErrorMessage('No member found with the provided phone number or email.');
                });
        }
    }, [email, phone]);

    // Fetch active members
    useEffect(() => {
        getActiveMembers()
            .then(response => {
                setActiveMembers(response.data.active_members || []);
            })
            .catch(error => {
                console.error('Error fetching active members:', error);
            });
    }, []);

    // Fetch canceled members
    useEffect(() => {
        getCancelledMembers()
            .then(response => {
                setCancelledMembers(response.data.canceled_members || []);
            })
            .catch(error => {
                console.error('Error fetching canceled members:', error);
            });
    }, []);

    // Handle form submission for specific member search
    const handleSearch = (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (searchQuery.phone === '' && searchQuery.email === '') {
            setErrorMessage('Please provide either a phone number or email.');
            return;
        }

        getMemberByPhoneOrEmail(searchQuery)
            .then(response => {
                setSpecificMember(response.data.member);
            })
            .catch(error => {
                setErrorMessage('No member found with the provided phone number or email.');
            });
    };

    return (
        <div className="container">
            <h2>Members Dashboard</h2>

            <h3>Active Members</h3>
            {activeMembers.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Next Billing Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeMembers.map(member => (
                            <tr key={member.id}>
                                <td>{member.customer_name}</td>
                                <td>{member.customer_email}</td>
                                <td>{member.status}</td>
                                <td>{new Date(member.current_period_end).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No active members found.</p>
            )}

            <h3>Canceled Members</h3>
            {cancelledMembers.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Last Billing Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cancelledMembers.map(member => (
                            <tr key={member.id}>
                                <td>{member.customer_name}</td>
                                <td>{member.customer_email}</td>
                                <td>{member.status}</td>
                                <td>{new Date(member.current_period_end).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No canceled members found.</p>
            )}

            <h3>Find Member by Phone or Email</h3>
            <form onSubmit={handleSearch}>
                <input
                    type="text"
                    placeholder="Phone"
                    value={searchQuery.phone}
                    onChange={(e) => setSearchQuery({ ...searchQuery, phone: e.target.value })}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={searchQuery.email}
                    onChange={(e) => setSearchQuery({ ...searchQuery, email: e.target.value })}
                />
                <button type="submit">Search</button>
            </form>

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            {specificMember && (
                <div>
                    <h3>Specific Member Details</h3>
                    <p>Name: {specificMember.customer_name}</p>
                    <p>Email: {specificMember.customer_email}</p>
                    <p>Phone: {specificMember.customer_phone}</p>
                    <p>Subscription Status: {specificMember.status}</p>
                    <p>Next Billing Date: {new Date(specificMember.current_period_end).toLocaleDateString()}</p>
                    <h4>Custom Fields:</h4>
                    <ul>
                        {specificMember.checkout_metadata.custom_fields &&
                            specificMember.checkout_metadata.custom_fields.map((field, index) => (
                                <li key={index}>
                                    {field.label.custom}: {field.text.value}
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MembersDashboard;
